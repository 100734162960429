<template>
  <FullPageSpinner v-if="loading" />
  <div v-else class="py-4 container-fluid mx-auto">
    <h1 class="h1 pt-4 display-2 fw-normal text-primary text-center">
      แก้ไขข้อมูล
    </h1>

    <div class="container mt-4">
      <form v-if="Object.keys(editDetail).length !== 0" class="needs-validation" novalidate @submit.prevent="onSubmit">
        <input-field v-for="(detail, index) in formDetails" :key="index" ref='inputFields' :detail="detail"
          :editDetail="editDetail" @emitToParent="getInput($event)" />
        <Button :disabled="!isEditted" type="submit" class="mt-5" label="บันทึกข้อมูล" />
      </form>
    </div>
  </div>
  <div v-if="showDialog">
    <div class="overlay" @click="showDialog = !showDialog"></div>
    <div>
      <div class="outer-div modal-dialog  modal-dialog-centered border-0 rounded-3">
        <div class="modal-content">
          <div class="modal-header border-0 ">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              @click="showDialog = !showDialog"></button>
          </div>
          <div class="modal-body d-flex justify-content-center">
            <p class=" text-danger mt-3 fw-light h4">บันทึกข้อมูลเรียบร้อย</p>
          </div>
          <div class="modal-footer d-flex justify-content-center border-0 ">
            <button type="button" class="btn btn-primary" @click="showDialog = !showDialog">ปิด</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InputField from '../../components/InputField.vue'
import Button from '../../components/Button.vue'
import * as api from '@/services'
import FullPageSpinner from '@/views/components/feedbacks/FullPageSpinner.vue'

export default {
  components: {
    InputField,
    Button,
    FullPageSpinner
  },
  async mounted() {
    const { data } = await api.getCustomerById(this.userId)
    this.editDetail = {
      name: data.name, birthdate: data.birthdate, phoneNumber: data.phoneNumber,
      email: data.email, province: data.province, referredShop: data.referredShop
    }
    this.loading = false
    if (!this.editDetail.referredShop) {
      this.$nextTick(function () {
        this.$refs.inputFields[4].setNullValue()
      })
    }
  },
  data() {
    return {
      submit: 'submit',
      isEditted: false,
      loading: true,
      formDetails: [
        {
          title: 'name',
          label: 'ชื่อจริง',
          type: 'text',
          placeholder: 'กรอกชื่อจริง',
        },
        {
          title: 'birthdate',
          label: 'วันเดือนปีเกิด',
          type: 'date',
          placeholder: 'กรอกวันเดือนปีเกิด',
        },
        {
          title: 'phoneNumber',
          label: 'หมายเลขโทรศัพท์',
          type: 'tel',
          placeholder: 'กรอกหมายเลขโทรศัพท์',
        },
        {
          title: 'email',
          label: 'อีเมล',
          type: 'email',
          placeholder: 'กรอกอีเมล',
        },
        {
          title: 'referredShop',
          label: 'ร้านค้าที่แนะนำ',
          type: 'select-shop',
          placeholder: 'กรอกชื่อร้านค้า',
        },
        {
          title: 'province',
          label: 'จังหวัด',
          type: 'select-province',
          placeholder: 'กรอกจังหวัด',
        },
      ],
      inputDetails: {},
      editDetail: {},
      showDialog: false,
    }
  },
  methods: {
    formValidate() {
      let isFinish = true
      if (Object.keys(this.inputDetails).length === 6) {
        Object.keys(this.inputDetails).forEach(value => {
          if (value != "referredShop" && (this.inputDetails[value] === '' || !this.inputDetails[value])) {
            isFinish = false
          }
        })
      }
      return isFinish
    },
    async onSubmit() {
      this.inputDetails['phoneNumber'] = `${this.inputDetails['phoneNumber']}`
      if (this.inputDetails.referredShop == "-") {
        this.inputDetails.referredShop = null //ตั้งให้กรอกไม่ระบุเป็นค่า - เพราะ vue select ไม่รองรับ null value
      }
      await api.patchCustomer(this.userId, { ...this.inputDetails })
      this.isEditted = false
      this.showDialog = true
      return
    },
    getInput(input) {
      this.inputDetails = { ...this.inputDetails, ...input }
    },
  },
  computed: {
    ...mapState(['userId']),
  },
  watch: {
    inputDetails: {
      handler() {
        this.isEditted = this.formValidate() 
      },
      deep: true,
    },
  },
}
</script>
