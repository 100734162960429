<template>
  <FullPageSpinner v-if="loading"></FullPageSpinner>
  <div v-else class="py-4 container-fluid mx-auto">
    <h1 class="h1 pt-4 display-2 fw-normal text-primary text-center">
      นโยบาย<br/>ความเป็นส่วนตัว
    </h1>
    <text-box class="mt-4">
      <check-box ref="checkbox " @toggleCheck="toggleCheck" />
    </text-box>
    <Button :disabled="!user.agreedToTerms" :onClick="onSubmit" class="mt-4" label="ต่อไป" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import TextBox from './components/TextBox.vue'
import CheckBox from './components/CheckBox.vue'
import Button from '../../components/Button.vue'
import * as api from '@/services'
import FullPageSpinner from '@/views/components/feedbacks/FullPageSpinner.vue'

export default {
  name: 'Term-Agreement',
  components: {
    TextBox,
    CheckBox,
    Button,
    FullPageSpinner
  },
  data() {
    return {
      loading: true,
      user: this.freshUserObject(),
    }
  },
  async mounted() {
    this.user.lineUid = await this.lineUid
    this.user.lineDisplayName = await this.lineDisplayname
    this.loading = false
  },
  methods: {
    ...mapActions(['setUserId']),
    async onSubmit() {
      await api.postCustomer(this.user).then((res) => {
        this.setUserId(res.data.id)
      }).then(() => {
        this.$router.push({
          path: `/personal-info`,
        })
      })

    },
    toggleCheck(val) {
      this.user.agreedToTerms = val
    },
    freshUserObject() {
      return {
        name: '',
        lineUid: '',
        lineDisplayName: '',
        volume: '',
        interval: '',
        cycle: '',
        province: '',
        referredShop: null,
        birthdate: '',
        phoneNumber: '',
        email: '',
        agreedToTerms: false,
      }
    },
  },
  computed: mapState(['userId', 'lineUid', 'lineDisplayname']),
}
</script>
<style scoped>
</style>
