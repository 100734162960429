<template>
    <div class=" vh-100 vw-100 top-0 left-0 position-fixed d-flex justify-content-center align-self-center align-items-center  "
        style="z-index: 1999;">
        <div class=" overlay"></div>
        <div class="">
            <div class="spinner-border text-danger m-5" style="z-index: 2000;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'SpinnerOverlay',
}
</script>
<style scoped>
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 4;
    cursor: pointer;
}
</style>