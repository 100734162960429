<template>
  <div class="row">
    <div :class="[
      info.length < 3 ? 'col-5' : 'col',
      'd-flex flex-column align-items-center',
    ]" v-for="(item, index) in info" :key="index">
      <label class="radio-box w-100 bg-white rounded-4" @change="updateAnswer(item)">
        <input type="radio" :name="name" :value="item.title" v-model="answer" />
        <p class="text-center text-primary fs-5 lh-sm py-4 w-100 text-sm-center mb-0" :v-html="item.title">
          <span v-if="item.number" class="fs-2">{{ item.number }}</span>{{ item.title }}
        </p>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'v-button',
  data() {
    return {
      answer: this.initAnswer ? this.initAnswer : [],
    }
  },
  props: {
    info: {
      required: true,
    },
    name: {
      required: true
    },
    detail: String,
    initAnswer: null,
  },
  methods: {
    updateAnswer(item) {
      this.$emit('update', item.title, this.name)
    },
  },
}
</script>
<style scoped>
/* HIDE RADIO */
[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

[type='radio']+p {
  border: 2px solid white;
  border-radius: 4px;
  font-weight: 300;
}

/* IMAGE STYLES */
[type='radio']+.radio-box {
  cursor: pointer;
}

/* CHECKED STYLES */
[type='radio']:checked+p {
  border: 2px solid #dc2d5a;
  border-radius: 4px;
  font-weight: 400;
}

.rounded-4 {
  border-radius: 4px;
}
</style>
