<template>
  <div class="mb-3 mx-3 d">
    <label :for="detail.label" class="form-label">{{ detail.label }}</label>

    <Datepicker v-if="detail.type == 'date'" placeholder="เลือกวันที่" v-model="date" class="mx-auto form-lg"
      :enable-time-picker="false" :maxDate="new Date()" @update:model-value="changeDate()" locale="th"
      :dayNames="['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส']" weekStart="0" format="dd/MM/yyyy" autoApply />

    <Datepicker v-else-if="detail.recorded_date" placeholder="เลือกวันที่" v-model="recorded_date"
      class="mx-auto form-lg" :enable-time-picker="false" :maxDate="new Date()" :clearable="false" :readonly="true"
      @click="toPickDatePage(detail)" format="dd/MM/yyyy" hideInputIcon >
    </Datepicker>

    <input v-else-if="detail.type == 'tel'" type="tel"
      onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
      class="form-control form-control-lg" :id="detail.title" :placeholder="detail.placeholder"
      v-model="input[detail.title]" @change="emitInput()" />

    <input v-else-if="detail.type == 'email'" :type="detail.type" class="form-control form-control-lg"
      :id="detail.title" :placeholder="detail.placeholder" v-model="input[detail.title]" @change="emitInput()" />

    <v-select v-else-if="detail.type == 'select-shop'" :options="shopOptions" :reduce="option => option.adjustedId"
      label="label" placeholder="เลือกร้านค้า" v-model="input[detail.title]" @update:modelValue="emitSelected(val)" />

    <v-select v-else-if="detail.type == 'select-province'" :options="provinces" placeholder="เลือกจังหวัด"
      v-model="input[detail.title]" @update:modelValue="emitSelected()" />

    <div v-else-if="detail.type == 'edit'" class="input-group mb-3">
      <input readonly type="text" class="form-control bg-white" v-model="input[detail.title]" />
    </div>

    <input v-else :type="detail.type" class="form-control form-control-lg" :id="detail.title"
      :placeholder="detail.placeholder" v-model="input[detail.title]" @change="emitInput()" />
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapActions } from 'vuex'
import Datepicker from '@vuepic/vue-datepicker'
import '../../assets/scss/vuepic_calendar.css'
import getProvinces from '@/utils/provinces'
  ; ('@/utils/provinces')
import vSelect from 'vue-select'
import '@/assets/scss/vue-select.css'

import * as api from '@/services'

export default {
  name: 'input-field',
  components: {
    Datepicker,
    vSelect,
  },
  props: {
    detail: Object,
    validate: String,
    editDetail: Object,
  },
  setup() {
    const date = ref()
    const recorded_date = ref()
    const provinces = getProvinces()
    return {
      date,
      recorded_date,
      provinces,
    }
  },
  data() {
    return {
      input: {},
      shops: [],
      shopOptions: [],
    }
  },
  methods: {
    ...mapActions(['setRecord']),
    emitInput() {
      this.$emit('emitToParent', this.input)
    },
    changeDate() {
      const isoDate = this.date?this.date.toISOString():null
      this.input['birthdate'] = isoDate
      this.$emit('emitToParent', this.input)
    },
    emitSelected() {
      this.$emit('emitToParent', this.input)
    },
    emitDeselected() {
      this.$emit('emitToParent', this.input)
    },
    setNullValue() {
      this.input.referredShop = '-' //ตั้งให้กรอกไม่ระบุเป็นค่า - เพราะ vue select ไม่รองรับ null value
    },
    toPickDatePage(detail) {
      this.setRecord(detail)
      this.$router.push({
        path: '/edit-date',
      })
    },
  },
  mounted() {
    if (this.editDetail && Object.keys(this.editDetail).length !== 0) {
      this.input = this.editDetail
      this.date = this.editDetail.birthdate
    }

    if (this.detail.recorded_date) {
      this.recorded_date = this.detail.recorded_date
    }

    api
      .getShops()
      .then(res => {
        //ตั้งให้กรอกไม่ระบุเป็นค่า - เพราะ vue select ไม่รองรับ null value
        this.shops = [{ adjustedId: "-", name: "ไม่ระบุ" }, ...res.data.results]
      })
      .then(() => {
        this.shopOptions = this.shops.map(x => {
          if (x.adjustedId == '-') {
            return {
              label: `ไม่ระบุ`,
              adjustedId: "-",
            }
          } else {
            return {
              label: `(${x.adjustedId}) ${x.name}`,
              adjustedId: x.adjustedId,
            }
          }

        })
      })
  },
}
</script>

<style>
.form-label {
  font-size: 1.25rem;
}

.form-control {
  font-size: 1.25rem;
}

</style>
