<template>
  <FullPageSpinner v-if="loading"></FullPageSpinner>
  <div v-else class="py-4 container-fluid mx-auto">
    <h1 v-if="this.$route.name != 'EditDate'" class="h1 pt-4 display-2 fw-normal text-primary text-center">
      ประจำเดือน<br />ครั้งล่าสุด
    </h1>
    <h1 v-else class="h1 pt-4 display-2 fw-normal text-primary text-center">
      แก้ไขวัน<br />ประจำเดือนมา
    </h1>
    <div class="container my-5 mx-auto d-flex justify-content-center">
      <Datepicker v-model="date" ref="" class="mx-auto hide-year" inline :enable-time-picker="false"
        monthNameFormat="long" :maxDate="new Date()" locale="th" weekStart="0"
        :dayNames="['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส']" autoApply />
    </div>
    <Button :onClick="handleSubmit" :disabled="!date || submitLoading" :class="[isiPad ? 'mt-3' : 'sticky-bottom']">
      บันทึกข้อมูล
      <LoadingSpinner v-if="submitLoading" />
    </Button>
  </div>
</template>

<script>
import { ref } from 'vue'
import Datepicker from '@vuepic/vue-datepicker'
import { mapActions, mapState } from 'vuex'
import '@/assets/scss/vuepic_calendar.css'
import Button from '@/views/components/Button.vue'
import * as api from '@/services'
import FullPageSpinner from '@/views/components/feedbacks/FullPageSpinner.vue'
import LoadingSpinner from '@/views/components/LoadingSpinner.vue'


export default {
  data() {
    return {
      loading: true,
      submitLoading: false
    }
  },
  components: {
    Datepicker,
    Button,
    FullPageSpinner,
    LoadingSpinner
  },
  methods: {
    ...mapActions(['clearRecord']),
    //ใช้เวลามาจาก edit ต้องส่งข้ามหน้า
    async handleSubmit() {
      this.submitLoading = true;
      this.date = new Date(this.date)
      const isoDate = this.date.toISOString()
      if (this.$route.name == "EditDate") { //ใช้เวลามาจาก edit ต้องส่งข้ามหน้า
        try {
          await api.patchRecord(this.record.id, isoDate)
          this.clearRecord()
          this.$router.push({
            path: '/edit-period',
          })
          this.submitLoading = false;
        } catch (e) {
          console.log(e)
          this.submitLoading = false;
        }
        return
      } else {
        try {
          await api.postRecords(this.userId, isoDate)
          this.submitLoading = false;
        }
        catch (e) {
          console.log(e)
          this.submitLoading = false;
        }
        this.$router.push({
          name: 'HowPage', params: { dialog: true }
        })
      }

    },
  },
  computed: {
    ...mapState(['userId', 'record']),
    isiPad() {
      return navigator.userAgent.match(/iPad/i)
    }
  },
  mounted() {
    if (this.$route.name == 'EditDate') this.date = this.record.recorded_date
    this.loading = false
  },
  setup() {
    const date = ref()
    return {
      date
    }
  },
}
</script>
<style>
@media (max-width:640px) {
  .sticky-bottom {
    width: 75%;
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width:641px) {
  .sticky-bottom {
    width: 75%;
    display: block;
    position: relative;
    margin-top: 80px;
  }
}
</style>
