<template>
  <FullPageSpinner v-if="loading" />
  <div v-else class="py-4 container-fluid mx-auto">
    <h1 class="h1 pt-4 display-2 fw-normal text-primary text-center">
      ลงทะเบียน
    </h1>
    <div class="container mt-4">
      <form class="needs-validation" novalidate @submit.prevent="onSubmit">
        <input-field v-for="(detail, index) in formDetails" :key="index" :detail="detail"
          @emitToParent="getInput($event)" />
        <Button :disabled="!isFinish" type="submit" class="mt-5" label="ต่อไป" />
      </form>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import InputField from '../../components/InputField.vue'
import Button from '../../components/Button.vue'
import * as api from '@/services'
import FullPageSpinner from '@/views/components/feedbacks/FullPageSpinner.vue'
export default {
  components: {
    InputField,
    Button,
    FullPageSpinner
  },
  data() {
    return {
      submit: 'submit',
      loading: true,
      formDetails: [
        {
          title: 'name',
          label: 'ชื่อจริง',
          type: 'text',
          placeholder: 'กรอกชื่อจริง',
        },
        {
          title: 'birthdate',
          label: 'วันเดือนปีเกิด',
          type: 'date',
          placeholder: 'กรอกวันเดือนปีเกิด',
        },
        {
          title: 'phoneNumber',
          label: 'หมายเลขโทรศัพท์',
          type: 'tel',
          placeholder: 'กรอกหมายเลขโทรศัพท์',
        },
        {
          title: 'email',
          label: 'อีเมล',
          type: 'email',
          placeholder: 'กรอกอีเมล',
        },
        {
          title: 'referredShop',
          label: 'ร้านค้าที่แนะนำ',
          type: 'select-shop',
          placeholder: 'กรอกชื่อร้านค้า',
        },
        {
          title: 'province',
          label: 'จังหวัด',
          type: 'select-province',
          placeholder: 'กรอกจังหวัด',
        },
      ],
      inputDetails: {},
    }
  },
  methods: {
    async onSubmit() {
      this.inputDetails['phoneNumber'] = `${this.inputDetails['phoneNumber']}`
      if (this.inputDetails.referredShop == "-") {
        this.inputDetails.referredShop = null //ตั้งให้กรอกไม่ระบุเป็นค่า - เพราะ vue select ไม่รองรับ null value
      }
      await api.patchCustomer(this.userId, { ...this.inputDetails })
      this.$router.push({
        path: `/period-info`,
      })

      return
    },
    getInput(input) {
      this.inputDetails = { ...this.inputDetails, ...input }
    },
  },
  computed: {
    ...mapState(['userId', 'lineUid']),
    isFinish() {
      let isFinish = true
      if (Object.keys(this.inputDetails).length !== 6) isFinish = false
      else {
        Object.keys(this.inputDetails).forEach(value => {
          if (value != "referredShop" && (this.inputDetails[value] === '' || !this.inputDetails[value])) {
            isFinish = false
          }
        })
      }
      return isFinish
    },
  },
  async mounted() {
    this.loading = false
  }
}
</script>
