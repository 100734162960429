<template>
    <div class=" middle">
        <h1 class="fw-normal text-primary text-center">
            404
        </h1>
        <p class="text-center">
            Page Not Found :(
        </p>
    </div>
</template>
<script>
export default {
    name: "ErrorPage"
}
</script>
<style>
.middle{
    position: absolute;
  width: 300px;
  height: 300px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;
}
.middle h1{
    font-size: 9rem;
}
</style>