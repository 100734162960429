import apiClient from "../axios";

function postCustomerSymptom(symptomId, customerId) {
  return apiClient.post("/customer-symptom", {
    symptomId,
    customerId,
  });
}

function deleteCustomerSymptom(id) {
  return apiClient.delete(`/customer-symptom/${id}`);
}

function getCustomerSymptomByCustomerId(customerId) {
  return apiClient.get(`/customer-symptom?customerId=${customerId}`);
}

export {
  postCustomerSymptom,
  getCustomerSymptomByCustomerId,
  deleteCustomerSymptom,
};
