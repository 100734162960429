<template>
  <FullPageSpinner v-if="loading" />
  <div v-else class="py-4 container-fluid mx-auto">
    <h1 class="h1 pt-4 display-2 fw-normal text-primary text-center">
      FAQ คำถามที่พบบ่อย
    </h1>
    <div class="mt-4  " >
      <div class="accordion accordion-flush" id="accordion">
        <div class="accordion-item mt-2" style="border-radius:0.4rem" v-for="(item, index) in data" :key="index">
          <h2 class="accordion-header bg-white" :id="'flush-heading' + index">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#flush-collapse' + index" aria-expanded="false"
              :aria-controls="'flush-collapse' + index">
              {{ item.title }}
            </button>
          </h2>
          <div :id="'flush-collapse' + index" class="accordion-collapse collapse"
            :aria-labelledby="'flush-heading' + index" data-bs-parent="#accordion">
            <div v-if="item.id == 2" class="accordion-body">
              ไปที่เมนู “บันทึกประจำเดือน” กดที่ไอคอนดินสอ <span>
                <font-awesome-icon  icon='fa-solid fa-pen-to-square' class='text-white bg-primary rounded-2'
                  style='position: relative;top: 7px;left: 1px;padding:6px;margin-top:-7px' />
              </span> เพื่อแก้ไขข้อมูล แล้วกดบันทึกข้อมูล
            </div>
            <div v-else class="accordion-body" v-html="item.html">
            </div>
          </div>
        </div>
      </div>
    </div>
    <Popup v-if="isShowPopup"></Popup>
  </div>
</template>

<script>
// vif index 2 เพราะไอคอนไม่ render
import Popup from "./components/WelcomeDialog.vue";
import FullPageSpinner from "@/views/components/feedbacks/FullPageSpinner.vue";
import data from "./utils/faqContents.json"

export default {
  data() {
    return {
      isShowPopup: false,
      loading: true,
      data: data
    };
  },
  components: {
    Popup,
    FullPageSpinner
  },
  mounted() {
    if (this.$route.params.dialog) {
      this.isShowPopup = true
    }
    this.loading = false
  }
};
</script>
