<template>

    <div v-if="showDialog" class="modal ">
        <div class="overlay" @click="handleCloseModal"></div>
        <div>
            <div class=" align-top modal-dialog-centered modal-dialog border-0 rounded-3 ">
                <div class="modal-content modal-bg ">
                    <div class="modal-header">
                        <h5 class="modal-title">รับของขวัญพรีเมี่ยม</h5>
                        <button type="button" class="btn-close" aria-label="Close" @click="handleCloseModal"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="validateForm">
                            <!-- First Name and Last Name -->
                            <div class="mb-3">
                                <label for="fullName" class="fs-5 mb-1">ชื่อจริง นามสกุล <span
                                        class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="fullName" v-model="formData.fullName">
                                <!-- <div class="form-error">
                                    กรุณาระบุบ้านเลขที่
                                </div> -->
                            </div>

                            <!-- Nickname -->
                            <div class="mb-3">
                                <label for="nickname" class="fs-5 mb-1">ชื่อเล่น</label>
                                <input type="text" class="form-control" id="nickname" v-model="formData.nickname">
                            </div>

                            <!-- Voucher Type -->
                            <div class="mb-3">
                                <label for="voucherType" class="fs-5 mb-1">ประเภท voucher<span
                                        class="text-danger">*</span>
                                    <br /> <span class="fs-6">(หากเลือกแล้วจะไม่สามารถเปลี่ยนได้)</span> </label>
                                <v-select :disabled="prefilledData && prefilledData.voucherType" :options="VOUCHER_TYPE"
                                    :reduce="option" label="label" placeholder="เลือกประเภท voucher"
                                    v-model="formData.voucherType" :searchable="false" :clearable="false" />


                            </div>
                            <div class=" fs-3 mt-4 mb-2">ที่อยู่</div>
                            <!-- Address Fields -->
                            <div class="mb-3">
                                <label for="houseNumber" class="fs-5 mb-1">บ้านเลขที่ <span
                                        class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="houseNumber" v-model="formData.houseNumber">
                                <!-- <div class="form-error">
                                    กรุณาระบุบ้านเลขที่
                                </div> -->
                            </div>

                            <div class="mb-3">
                                <label for="moo" class="fs-5 mb-1">หมู่ <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="moo" v-model="formData.moo">
                                <!-- <div class="form-error">
                                    กรุณาระบุหมู่
                                </div> -->
                            </div>

                            <div class="mb-3">
                                <label for="villageOrAlley" class="fs-5 mb-1">หมู่บ้าน/ซอย </label>
                                <input type="text" class="form-control" id="villageOrAlley"
                                    v-model="formData.villageOrAlley">
                                <!-- <div class="form-error">
                                    กรุณาระบุหมู่บ้าน/ซอย
                                </div> -->
                            </div>

                            <!-- Optional Fields -->
                            <div class="mb-3">
                                <label for="building" class="fs-5 mb-1">ตึก/อาคาร</label>
                                <input type="text" class="form-control" id="building" v-model="formData.building">
                            </div>

                            <div class="mb-3">
                                <label for="floor" class="fs-5 mb-1">ชั้น</label>
                                <input type="text" class="form-control" id="floor" v-model="formData.floor">
                            </div>

                            <div class="mb-3">
                                <label for="road" class="fs-5 mb-1">ถนน</label>
                                <input type="text" class="form-control" id="road" v-model="formData.road">
                            </div>


                            <div class="mb-3">
                                <label for="province" class="fs-5 mb-1">จังหวัด <span
                                        class="text-danger">*</span></label>
                                <!-- <input type="text" class="form-control" id="province" v-model="formData.province"
                                    required> -->
                                <v-select :options="PROVINCE_OPTIONS" id="province" :reduce="option"
                                    v-model="formData.province" :clearable="false" :filterable="true"
                                    @option:selected="resetDistrict" />
                                <!-- <div class="form-error">
                                    กรุณาระบุจังหวัด
                                </div> -->
                            </div>

                            <div class="mb-3">
                                <label for="district" class="fs-5 mb-1">อำเภอ/เขต <span
                                        class="text-danger">*</span></label>
                                <v-select :disabled="!formData.province" :options="AMPHURE_OPTIONS" id="district"
                                    :reduce="option" v-model="formData.district" :clearable="false" :filterable="true"
                                    @option:selected="resetSubDistrict" />
                                <!-- <div class="form-error">
                                    กรุณาระบุอำเภอ/เขต
                                </div> -->
                            </div>


                            <div class="mb-3">
                                <label for="subDistrict" class="fs-5 mb-1">ตำบล/แขวง <span
                                        class="text-danger">*</span></label>
                                <v-select :disabled="!formData.district" :options="TAMBON_OPTIONS" id="subDistrict"
                                    :reduce="option" v-model="formData.subDistrict" :clearable="false"
                                    :filterable="true" @option:selected="handleSetPostalCode" />
                                <!-- <div class="form-error">
                                    กรุณาระบุตำบล/แขวง
                                </div> -->
                            </div>


                            <div class="mb-3">
                                <label for="postalCode" class="fs-5 mb-1">รหัสไปรษณีย์ <span
                                        class="text-danger">*</span></label>
                                <v-select :disabled="!formData.subDistrict" :options="POSTALCODE_OPTIONS"
                                    id="postalCode" :reduce="option" v-model="formData.postalCode" :clearable="false"
                                    :filterable="true" />
                                <!-- <div class="form-error">
                                    กรุณาระบุรหัสไปรษณีย์
                                </div> -->
                            </div>
                            <div class=" fs-3 mt-4 mb-2">ข้อมูลติดต่อ</div>
                            <div class="mb-3">
                                <label for="phoneNumber" class="fs-5 mb-1">เบอรโทรศัพท์ <span
                                        class="text-danger">*</span></label>
                                <input type="tel" :class="`form-control ${errorMessage.phoneNumber && ' input-error'}`"
                                    id="phoneNumber" v-model="formData.phoneNumber" inputmode="tel">
                                <div class="form-error">
                                    {{ errorMessage.phoneNumber }}
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="lineId" class="fs-5 mb-1">LINE ID <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="lineId" v-model="formData.lineId">
                                <!-- <div class="form-error">
                                    กรุณาระบุ LINE ID
                                </div> -->
                            </div>

                            <div class=" fs-3 mt-4 mb-2">ประวัติการซื้อ</div>
                            <div class="mb-3">
                                <label for="receiptImage" class="fs-5 mb-1">Upload รูปใบเสร็จ <span
                                        class="text-danger">*</span></label>
                                <input type="file" accept="image/*"
                                    :class="`form-control ${errorMessage.receiptImage && ' input-error'}`"
                                    id="receiptImage" @change="handleFileUpload" :v-model="formData.receiptImageName">
                                <div class="form-error">
                                    {{ errorMessage.receiptImage }}
                                </div>
                            </div>

                            <div class="mb-3">
                                <label class=" fs-5 mt-2 mb-1">ร้านค้าที่ร่วมรายการ <span
                                        class="text-danger">*</span></label>
                                <v-select :options="shopOptions" :reduce="option => option.adjustedId" label="label"
                                    placeholder="เลือกร้านค้า" v-model="formData.selectedShop" />
                                <p class=" text-danger mt-3 fw-light">*หากกดรับแล้วไม่สามารถแก้ไขได้ ไม่ว่ากรณีใดๆ</p>
                            </div>

                            <div class="modal-footer d-flex justify-content-center">
                                <button type="submit" class="btn btn-primary"
                                    :disabled="!isRequiredFieldComplete">ยืนยัน</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vSelect from 'vue-select';
import province from '@/data/provinces.json';
import amphures from '@/data/amphures.json';
import tambons from '@/data/tambons.json';
import voucherOptions from '@/data/voucherOptions.json';

const defaultFormValue = {
    fullName: '',
    nickname: '',
    voucherType: '',
    houseNumber: '',
    moo: '',
    villageOrAlley: '',
    building: '',
    floor: '',
    road: '',
    subDistrict: '',
    district: '',
    province: '',
    postalCode: '',
    phoneNumber: '',
    lineId: '',
    receiptImage: null,
    receiptImageName: '',
    selectedShop: null,
}

const defaultErrorMessage = {
    phoneNumber: '',
    receiptImage: '',
}

const requiredField = [
    'fullName',
    'voucherType',
    'houseNumber',
    'moo',
    'province',
    'district',
    'subDistrict',
    'postalCode',
    'phoneNumber',
    'lineId',
    'receiptImage',
    'selectedShop'
];

export default {
    name: 'redemption-form',
    components: {
        vSelect,
    },
    watch: {
        formData: {
            handler() {
                this.validateRequiredField();
            },
            deep: true,
        },
        prefilledData: {
            handler() {
                if (this.prefilledData) {
                    const selectedP = province.find(p => p.name_th === this.prefilledData.province)
                    const selectedA = amphures.find(a => a.name_th === this.prefilledData.district)
                    const selectedT = tambons.find(t => t.name_th === this.prefilledData.subDistrict)
                    this.formData = {
                        ...this.prefilledData,
                        province: {
                            label: selectedP.name_th,
                            value: selectedP.id,
                        },
                        district: {
                            label: selectedA.name_th,
                            value: selectedA.id,
                        },
                        subDistrict: {
                            label: selectedT.name_th,
                            value: selectedT.id,
                        },
                        postalCode: {
                            label: selectedT.zip_code,
                            value: selectedT.zip_code,
                        },
                        voucherType: {
                            label: this.prefilledData.voucherType,
                            value: this.prefilledData.voucherType,
                        }
                    }
                }

            },
            deep: true,
        },
    },
    computed: {
        VOUCHER_TYPE() {
            return voucherOptions
        },
        PROVINCE_OPTIONS() {
            return province.map((p) => ({
                label: p.name_th,
                value: p.id,
            })).sort((a, b) => a.label.localeCompare(b.label));
        },
        AMPHURE_OPTIONS() {
            return amphures.filter((a) => a.province_id === this.formData.province.value).map((a) => ({
                label: a.name_th,
                value: a.id,
            })).sort((a, b) => a.label.localeCompare(b.label));
        },
        TAMBON_OPTIONS() {
            return tambons.filter((t) => t.amphure_id === this.formData.district.value).map((t) => ({
                label: t.name_th,
                value: t.id,
            })).sort((a, b) => a.label.localeCompare(b.label));
        },
        POSTALCODE_OPTIONS() {
            return tambons.filter((t) => t.name_th === this.formData.subDistrict.label).map((t) => ({
                label: t.zip_code,
                value: t.zip_code,
            })).sort((a, b) => {
                return a.label - b.label;
            });
        }
    },
    props: {
        showDialog: Boolean,
        shopOptions: {
            type: Array,
            required: true,
            default: () => [],
        },
        onClose: {
            type: Function,
            required: true,
        },
        onSubmit: {
            type: Function,
            required: true,
        },
        prefilledData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            formData: this.prefilledData ? { ...this.prefilledData } : { ...defaultFormValue },
            errorMessage: { ...defaultErrorMessage },
            isFormComplete: false,
            isRequiredFieldComplete: false,
        };
    },
    methods: {
        resetDistrict() {
            this.formData.district = ''
            this.resetSubDistrict()
        },
        resetSubDistrict() {
            this.formData.subDistrict = ''
            this.formData.postalCode = ''
        },
        resetForm() {
            this.formData = this.prefilledData ? { ...this.prefilledData } : { ...defaultFormValue }
            this.errorMessage = { ...defaultErrorMessage }
            this.isRequiredFieldComplete = false
            this.isFormComplete = false
        },
        handleCloseModal() {
            this.resetForm()
            this.onClose();
        },
        handleFileUpload(event) {
            // if (event.target.files[0].size > 2097152) {
            //     event.preventDefault();
            //     this.errorMessage.receiptImage = 'ไฟล์รูปภาพต้องมีขนาดไม่เกิน 2MB'
            //     this.formData.receiptImage = null
            //     this.formData.receiptImageName = ''
            //     return false
            // } else {
            this.errorMessage.receiptImage = ''
            this.formData.receiptImage = event.target.files[0];
            this.formData.receiptImageName = event.target.files[0].name;
            return true
            // }
        },
        handleSetPostalCode() {
            this.formData.postalCode = this.POSTALCODE_OPTIONS[0];
        },
        validateForm() {
            const regex = new RegExp(/^(0[689]{1})+([0-9]{8})+$/)
            if (!regex.test(this.formData.phoneNumber)) {
                this.errorMessage.phoneNumber = 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง'
                return;
            } else {
                this.errorMessage.phoneNumber = ''
            }
            if (this.validateRequiredField()) {
                this.handleSubmit()
            }
        },
        validateRequiredField() {
            const isFormComplete = requiredField.every((field) => this.formData[field]);
            this.isRequiredFieldComplete = isFormComplete;
            return isFormComplete;
        },
        async handleSubmit() {
            await this.onSubmit(this.formData)
            this.handleCloseModal()
        },
    },

};
</script>


<style scoped>
.modal-bg {
    background-image: url('../../../../public/image/background.png');
    background-size: cover;
    margin: auto !important;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: block;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
    cursor: pointer;
}

.outer-div,
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
    z-index: 1055;
    transform: translate(0, 0);
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
}

.rounded-3 {
    border-radius: 12%;
}

.form-error {
    color: red;
    padding-top: 0.25rem;
    font-size: 0.8rem;
    font-weight: 300;
}

.input-error {
    border-color: red;
}
</style>