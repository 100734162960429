async function uploadFile(userId, fileName, file) {

  const formData = new FormData();

  const isodate = new Date()
    .toISOString()
    .replaceAll(':', '')
    .replaceAll('-', '')
    .replaceAll('.', '');

  formData.append('file', file, `${fileName}-${isodate}`);

  var requestOptions = {
    method: 'POST',
    body: formData,
  };

  const data = await fetch(`https://api.ncareclub.com/v1/files/upload/${userId}`, requestOptions);
  const response = await data.json();
  return response;
}

export { uploadFile };
