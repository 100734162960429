<template>

    <full-page-spinner v-if="loading" />
    <div v-else class="py-4 container-fluid mx-auto">
        <h1 class="h1 pt-4 display-2 fw-normal text-primary text-center">
            รับของขวัญพรีเมี่ยม
        </h1>
        <div class="alert alert-light text-black " role="alert">
            <ol style="margin-left: -10px;">
                <li>กดที่กล่องของขวัญ</li>
                <li>กดปุ่ม ‘ลงทะเบียนรับของขวัญที่นี่’ และกรอกข้อมูลในแบบฟอร์มให้ครบถ้วน</li>
                <li>คลิกที่ช่อง ‘ยืนยันการลงทะเบียน’</li>
                <li>ค้นหาและเลือกร้านค้าที่ร่วมรายการ</li>
                <li>กดยืนยัน </li>
            </ol>
            **กล่องของขวัญที่มีการยืนยันแล้ว จะเป็นสีเทาและกดลงทะเบียนซ้ำไม่ได้อีก
            ดังนั้นอย่าลืมตรวจสอบความถูกต้องให้ครบถ้วน
            <br /><br />
            **1 คนต่อ 1สิทธ์ (3กล่อง ต่อ 1 ใบ )

        </div>


        <div class=" row ">
            <div class="col-6 position-relative my-2" v-for="index in 3" :key="index">

                <img :src="`image/gift-${index}.png`" :alt="`gift-option-${index}`"
                    :class="[remainingBox + index <= 6 ? 'used' : '', 'w-100 gift-shadow rounded-4']"
                    @click="OpenDialog(index)" />
                <div v-if="(remainingBox + index) <= 6" class="top-right">ใช้แล้ว</div>
            </div>
        </div>
    </div>
    <redemption-form :showDialog="showDialog" :onClose="handleCloseDialog" :shopOptions="shopOptions"
        :prefilledData="prefilledData" :onSubmit="handleSubmit" />
    <spinner-overlay-vue v-if="isApiLoading" />
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import '@/assets/scss/vue-select.css';
import * as api from '@/services';
import vClickOutside from "click-outside-vue3";
import { mapState } from 'vuex';
import FullPageSpinner from '@/views/components/feedbacks/FullPageSpinner.vue';
import SpinnerOverlayVue from '@/views/components/feedbacks/SpinnerOverlay.vue';
import RedemptionForm from '@/views/components/forms/LongRedemptionForm.vue';
import voucherOptions from '@/data/voucherOptions.json';

export default {
    name: 'GiftPage',
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        FullPageSpinner,
        SpinnerOverlayVue,
        RedemptionForm
    },
    data() {
        return {
            remainingBox: null,
            showDialog: false,
            shopOptions: [],
            // selectedShop: "",
            clickOnBox: "",
            loading: true,
            isApiLoading: false,
            isClickOutSide: false,
            isClickOnBox: false,
            isFormComplete: false,
            prefilledData: null
        }
    },
    methods: {

        handleCloseDialog() {
            this.showDialog = !this.showDialog
        },
        OpenDialog(index) {
            if (this.remainingBox + index > 6) {
                this.showDialog = !this.showDialog
            }
        },
        toggleCompleteFormCheck(val) {
            this.isFormComplete = val
        },
        async getPrefilledData() {
            api.getRedemptionsFormDataByUserId(this.userId).then((res) => {
                if (res.data.results.length == 0) return
                const data = res.data.results.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]
                this.prefilledData = {
                    fullName: data.full_name,
                    nickname: data.nickname,
                    voucherType: voucherOptions.find(x => x.label === data.voucher_type).label,
                    houseNumber: data.house_number,
                    moo: data.group,
                    villageOrAlley: data.village_or_alley,
                    building: data.building,
                    floor: data.floor,
                    road: data.road,
                    subDistrict: data.subdistrict,
                    district: data.district,
                    province: data.province,
                    postalCode: data.postal_code,
                    phoneNumber: data.telephone_number,
                    lineId: data.line_id,
                }

            }).catch((err) => {
                console.log(err)
                this.prefilledData = null
            })
        },
        async handleUploadFileToApi(shopId, file) {
            return api.uploadFile(this.userId, `redemption-${6 - this.remainingBox}-from-${shopId}-${uuidv4()}`, file)
        },
        async handleSubmit(data) {
            try {
                this.isApiLoading = true
                const { url } = await this.handleUploadFileToApi(data.selectedShop, data.receiptImage)
                const postData = {
                    user_id: this.userId,
                    full_name: data.fullName,
                    nickname: data.nickname,
                    house_number: data.houseNumber,
                    group: data.moo,
                    village_or_alley: data.villageOrAlley,
                    building: data.building,
                    floor: data.floor,
                    road: data.road,
                    subdistrict: data.subDistrict.label,
                    district: data.district.label,
                    province: data.province.label,
                    postal_code: data.postalCode.label.toString(),
                    telephone_number: data.phoneNumber,
                    line_id: data.lineId,
                    receipt_picture_url: url,
                    voucher_type: this.prefilledData ? this.prefilledData.voucherType : data.voucherType.label
                }
                console.log(postData)
                await api.postRedemptionForm(postData)
                await api.postRedemptions({
                    "redeemed_by": this.userId,
                    "redeemed_at": data.selectedShop
                })
                await api
                    .getCustomerById(this.userId)
                    .then(res => {
                        this.remainingBox = res.data.remainingBoxes;
                    })
                this.getPrefilledData()
                this.isApiLoading = false
            } catch (error) {
                console.log(error)
                alert('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
                this.isApiLoading = false
            }
        }

    },
    computed: {
        ...mapState(['userId'])
    },
    mounted() {
        api
            .getShops()
            .then(res => {
                this.shops = res.data.results;
            })
            .then(() => {
                this.shopOptions = this.shops.map(x => {
                    return {
                        label: `(${x.adjustedId}) ${x.name}`,
                        adjustedId: x.adjustedId,
                    };
                });
            });

        api
            .getCustomerById(this.userId)
            .then(res => {
                this.remainingBox = res.data.remainingBoxes;
                this.loading = false
            })
        this.getPrefilledData()
    }
}
</script>

<style>
.gift-shadow {
    box-shadow: 0 0 0.5rem rgb(0 0 0 / 10%) !important;
    border-radius: 1.1rem;
}

.used {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.top-right {
    position: absolute;
    top: 0px;
    right: 10%;
    color: #606060
}


.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
    cursor: pointer;
}

.outer-div {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 1055;
}

.fixed-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal-content {
    margin: 0 0.5rem
}

.modal-dialog {
    margin: 0
}

.rounded-4 {
    border-radius: 12%;
}
</style>