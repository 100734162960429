<template>
  <div class="container gap-2 col-11 mx-auto form-check mt-4">
    <input
      class="form-check-input mt-2"
      type="checkbox"
      v-model="checkbox"
      id="flexCheckDefault"
      @change="$emit('toggleCheck',checkbox)"
      required
    />
    <label class="form-check-label" for="flexCheckDefault">
      ฉันได้อ่านครบถ้วนและยอมรับนโยบายความเป็นส่วนตัว
    </label>
  </div>
</template>

<script>
export default {
  name: 'check-box',
  data() {
    return {
      checkbox: false,
    };
  },
};
</script>

<style scoped>
.form-check-label {
  font-size: 1.2rem;
}
</style>
