import { createWebHistory, createRouter } from 'vue-router';
import PickLastDatePage from '@/views/pages/Calendar/CalendarPage.vue';
import PeriodInfo from '@/views/pages/Registration/PeriodInfoPage.vue';
import PersonalInfo from '@/views/pages/Registration/PersonalInfoPage.vue';
import Agreement from '@/views/pages/Registration/AgreementPage.vue';
import GiftPage from '@/views/pages/Account/GiftPage.vue';
import EditInfo from '@/views/pages/Account/EditInfoPage.vue';
import EditPeriod from '@/views/pages/Account/EditPeriodPage.vue';
import PageNotFound from '@/views/pages/404.vue';
import LoadingPage from '@/views/components/feedbacks/FullPageSpinner.vue';
import HowToPage from '@/views/pages/HowTo/HowToPage.vue';
import * as api from '@/services';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'LoadingPage',
    component: LoadingPage,
    meta: {
      title: 'NCare',
      isRegisPage: false,
      isEntryPage: true,
    },
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: Agreement,
    meta: {
      title: 'NCare - สมัครสมาชิก',
      isRegisPage: true,
      isEntryPage: false,
    },
  },
  {
    path: '/personal-info',
    name: 'PersonalInfo',
    component: PersonalInfo,
    meta: {
      title: 'NCare - สมัครสมาชิก',
      isRegisPage: true,
      isEntryPage: false,
    },
  },
  {
    path: '/period-info',
    name: 'PeriodInfo',
    component: PeriodInfo,
    meta: {
      title: 'NCare - สมัครสมาชิก',
      isRegisPage: true,
      isEntryPage: false,
    },
  },
  {
    path: '/pick-date',
    name: 'PickDate',
    component: PickLastDatePage,
    meta: {
      title: 'NCare - สมัครสมาชิก',
      isRegisPage: true,
      isEntryPage: false,
    },
  },
  {
    path: '/edit-info',
    name: 'EditInfo',
    component: EditInfo,
    meta: {
      title: 'NCare - แก้ไขข้อมูล',
      isRegisPage: false,
      isEntryPage: false,
    },
  },
  {
    path: '/edit-period-info',
    name: 'EditPeriodInfo',
    component: PeriodInfo,
    meta: {
      title: 'NCare - แก้ไขข้อมูล',
      isRegisPage: false,
      isEntryPage: false,
    },
  },
  {
    path: '/edit-period',
    name: 'EditPeriod',
    component: EditPeriod,
    meta: {
      title: 'NCare - บันทึกประจำเดือน',
      isRegisPage: false,
      isEntryPage: true,
    },
  },
  {
    path: '/edit-date',
    name: 'EditDate',
    component: PickLastDatePage,
    meta: {
      title: 'NCare - แก้ไขวันที่',
      isRegisPage: false,
      isEntryPage: false,
    },
  },
  {
    path: '/premium-gift',
    name: 'Premium Gift',
    component: GiftPage,
    meta: {
      title: 'NCare - รับของขวัญ',
      isRegisPage: false,
      isEntryPage: true,
    },
  },
  {
    path: '/how',
    name: 'HowPage',
    component: HowToPage,
    meta: {
      title: 'NCare - วิธีการใช้งาน',
      isRegisPage: false,
      isEntryPage: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      title: 'Page Not Found :(',
      isRegisPage: false,
      isEntryPage: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.lineUid || to.meta.isEntryPage) {
    // test mode
    // await window.liff.init({ liffId: '1660658846-YJ6dxzay' });
    //live mode
    await window.liff.init({ liffId: '1660658846-mqn8A2xE' });
    await window.liff.ready.then(async () => {
      if (window.liff.isInClient()) {
        await window.liff
          .getProfile()
          .then(async (profile) => {
            console.log(profile.userId);
            store.commit('SET_LINEUID', profile.userId);
            store.commit('SET_LINEDISPLAYNAME', profile.displayName);
          })
          .catch(function (error) {
            console.log(error);
            throw new Error('Error getting profile: ' + error);
          });
      } else {
        window.location.href = 'https://page.line.me/398javpp';
      }
    });
  }
  // test mode
  // await window.liff.init({ liffId: '1660658846-YJ6dxzay' });
  // store.commit('SET_LINEUID', 'U3a72dc1d075ae916df655f2015e18823');
  // store.commit('SET_LINEDISPLAYNAME', 'Earn');
  // }

  const { data } = await api.getCustomerByLineUid(store.state.lineUid);
  if (data.results.length != 0) {
    await store.commit('SET_USERID', data.results[0].id);
  }
  if (to.name == 'HowPage' || to.name == 'PageNotFound' || to.name == 'LoadingPage') {
    next();
  }
  //If haven't register go to /agreement
  else if (data.results.length === 0 && to.name !== 'Agreement') {
    document.title = 'NCare - สมัครสมาชิก';
    next({ name: 'Agreement' });
  }
  // //If haven't add personal info go to /personal-info
  else if (data.results.length !== 0 && data.results[0].name === '' && to.name !== 'PersonalInfo') {
    document.title = 'NCare - สมัครสมาชิก';
    next({ path: '/personal-info' });
  }
  // //If haven't add period-info go to /period-info
  else if (
    data.results.length !== 0 &&
    data.results[0].name !== '' &&
    data.results[0].volume === '' &&
    to.name !== 'PeriodInfo'
  ) {
    document.title = 'NCare - สมัครสมาชิก';
    next({ path: '/period-info' });
  }
  // //If haven't add first record go to /pick-date
  else if (
    data.results.length !== 0 &&
    data.results[0].name !== '' &&
    data.results[0].volume !== '' &&
    data.results[0].records.length === 0 &&
    to.name !== 'PickDate'
  ) {
    document.title = 'NCare - สมัครสมาชิก';
    next({ path: '/pick-date' });
  } else if (
    data.results.length !== 0 &&
    data.results[0].name !== '' &&
    data.results[0].volume !== '' &&
    data.results[0].records.length !== 0 &&
    to.meta.isRegisPage
  ) {
    next({ path: '/edit-info' });
  } else {
    document.title = to.meta.title;
    next();
  }
});

export default router;
