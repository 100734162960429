<template>
  <div class="d-grid gap-2 col-8 mx-auto">
    <button
      @click="onClick"
      :type="type"
      class="btn btn-primary w-100 rounded-pill btn-lg text-xl-center d-flex align-items-center justify-content-center"
      :disabled="disabled"
    >
      <slot>{{ label }}</slot>
    </button>
  </div>
</template>
<script>
export default {
  name: 'v-button',
  props: {
    onClick: {
      type: Function,
      // required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: String,
    label: String,
  },
};
</script>
<style></style>
