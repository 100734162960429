<template>
  <div class="card mb-10 p-2 border-0 rounded-5">
    <div class="card-body overflow-scroll ">
      <p class="card-text pt-0 mt-0 agreement-card">
        บริษัท ไบโอฟาร์ม เคมิคัลส์ จำกัด (“บริษัท”)
        คำนึงถึงสิทธิความเป็นส่วนตัวของข้อมูลส่วนบุคคลของท่าน
        และมุ่งมั่นที่จะดูแลความปลอดภัยและคุ้มครองของข้อมูลส่วนบุคคลดังกล่าว รวมถึงจะเก็บ รวบรวม
        ใช้และเปิดเผยข้อมูลส่วนบุคคลของท่านตามนโยบายของบริษัท ซึ่งสอดคล้องตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
        2562 (“พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล”) และกฎหมายที่เกี่ยวข้อง
        <br />
      </p>
      <ol class=" mb-0">
        <li>
          <strong>การจัดเก็บข้อมูล</strong><br />
          บริษัทขอเรียนแจ้งว่า บริษัท ไม่ได้มีการจัดการเก็บข้อมูลส่วนบุคคล ที่สามารถระบุตัวตนได้ แต่บริษัท
          อาจเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของท่านดังต่อไปนี้
          <ul>
            <li>
              ข้อมูลส่วนบุคคลอ่อนไหว เช่น ข้อมูลสุขภาพและโรคประจำตัว
            </li>
            <li>
              ข้อมูลติดต่อ เช่น ชื่อ เบอร์โทรศัพท์มือถือ อีเมลล์
            </li>
            <li>
              ข้อมูลสารสนเทศ หมายถึง ข้อมูลการเข้าใข้งาน Line Official Account และข้อมูลอื่นๆ ที่ถูกบันทึก
            </li>
          </ul>
        </li><br />
        <li>
          <strong>วิธีการเก็บรักษาข้อมูลส่วนบุคคล</strong><br />
          เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์ เราเก็บรักษาข้อมูลส่วนบุคคลของท่านดังต่อไปนี้
          <ul>
            <li>
              ผู้ให้บริการเซิร์ฟเวอร์ในต่างประเทศ
            </li>
          </ul>
        </li><br />
        <li>
          <strong>ระยะเวลาการเก็บรักษาข้อมูลส่วนบุคคล</strong><br />
          บริษัทจะเก็บรักษาข้อมูลของท่านข้างต้น เป็นระยะเวลาเท่าที่จำเป็นเท่านั้น
          เพื่อให้บรรลุวัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล ทั้งนี้ ไม่เกิน 10 ปีนับแต่ (ก) นิติสัมพันธ์ระหว่างท่าน
          และบริษัท สิ้นสุดลง หรือ (ข) วันที่บริษัท ได้รับข้อมูล
        </li><br />
        <li>
          <strong>วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล</strong><br />
          ข้อมูลส่วนบุคคลของท่านจะถูกเก็บรวบรวม ใช้และเปิดเผยเพื่อวัตถุประสงค์ตามที่ระบุไว้ด้านล่าง
          รวมถึงวัตถุประสงค์ใดอื่นที่ระบุในนโยบายคุ้มครองข้อมูลส่วนบุคคล หรือที่ท่านได้ให้ความยินยอมไว้ หรือที่ พ.ร.บ.
          คุ้มครองข้อมูลส่วนบุคคล และ/หรือกฎหมายอื่นที่เกี่ยวข้องกำหนด
          <ul>
            <li>
              การปฏิบัติหน้าที่ตามกฎหมาย
              <ol>
                <li>
                  เพื่อปฏิบัติตามข้อกำหนดของกฎหมายที่เกี่ยวข้อง เช่น พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล
                  ประมวลกฎหมายแพ่งและพาณิชย์
                  เป็นต้น
                </li>
                <li>
                  เพื่อปฏิบัติตามคำสั่งของศาล
                </li>
              </ol>
            </li>
            <li>
              ตามวัตถุประสงค์การใช้งาน
              <ol>
                <li>
                  เพื่อการจัดกิจกรรมตามที่เจ้าของข้อมูลแสดงเจตนาในการเข้าร่วมกิจกรรมนั้น ๆ เช่น การบันทึกข้อมูลสุขภาพ
                  เพื่อให้เจ้าของสามารถควบคุมภาวะของโรคต่างๆได้ เพื่อบันทึกการทานยา การคุมอาหารและการออกกำลังกาย
                </li>
                <li>
                  เพื่อยืนยันตัวตนในการสมัคร เข้าใช้งาน Line official Account
                </li>
              </ol>
            </li>
          </ul>
        </li><br />
        <li>
          <strong>การถ่ายโอนข้อมูลไปต่างประเทศ</strong><br />
          เราอาจส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศเพื่อดำเนินการตามวัตถุประสงค์ในการให้บริการแก่ท่าน เช่น
          เพื่อส่งข้อมูลส่วนบุคคลไปยังระบบคลาวด์ (Cloud) ที่มีแพลตฟอร์มหรือเครื่องแม่ข่าย (Server) อยู่ต่างประเทศ
          เพื่อสนับสนุนระบบเทคโนโลยีสารสนเทศที่ตั้งอยู่นอกประเทศไทย<br /><br />
          อย่างไรก็ตาม ในขณะที่จัดทำนโยบายฉบับนี้
          คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลยังมิได้มีประกาศกำหนดรายการประเทศปลายทางที่มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอ
          ดังนี้
          เมื่อเรามีความจำเป็นต้องส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังประเทศปลายทางเราจะดำเนินการเพื่อให้ข้อมูลส่วนบุคคลที่ส่งหรือโอนไปมีมาตรการคุ้มครองข้อมูลส่วนบุคคลอย่างเพียงพอตามมาตรฐานสากล
          หรือดำเนินการตามเงื่อนไขเพื่อให้สามารถส่งหรือโอนข้อมูลนั้นได้ตามกฎหมาย ได้แก่
          <ol>
            <li>เป็นการปฏิบัติตามกฎหมายที่กำหนดให้เราต้องส่งหรือโอนข้อมูลส่วนบุคคลไปต่างประเทศ</li>
            <li>ได้แจ้งให้ท่านทราบและได้รับความยินยอมจากท่าน</li>
            <li>เป็นการกระทำเพื่อประโยชน์ของท่าน</li>
          </ol>
        </li><br />
        <li>
          <strong>มาตรการการรักษาความมั่นคงความปลอดภัยของข้อมูลส่วนบุคคล</strong><br />
          บริษัทตระหนักถึงความสำคัญของการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของท่าน
          บริษัทจึงได้มีการใช้มาตรการรักษาความมั่นคงปลอดภัยที่เหมาะสมเพื่อดูแลรักษาข้อมูลส่วนบุคคลของท่านให้ปลอดภัย
          ซึ่งรวมถึงการควบคุมการเข้าถึง การเข้ารหัสข้อมูล ที่เก็บข้อมูลส่วนบุคคล
        </li><br />
        <li>
          <strong>สิทธิของเจ้าของข้อมูล</strong><br />
          เจ้าของข้อมูล มีสิทธิ์ ขอให้แก้ไข ปรับปรุง เข้าถึง ขอสำเนา ลบ ระงับ คัดค้าน แก้ไข ร้องเรียน
          เพิกถอนให้ความยินยอมของข้อมูลได้
        </li><br />
        <li>
          <strong>บททั่วไป</strong><br />
          นโยบายความเป็นส่วนตัวนี้อยู่ภายใต้การกำกับ และการตีความตาม พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล
          และกฎหมายแห่งประเทศไทย
        </li><br />
        <li>
          <strong>ติดต่อเรา</strong><br />
          หากท่านมีข้อสงสัยหรือต้องการสอบถามรายละเอียดเพิ่มเติมเกี่ยวกับนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
          หรือใช้สิทธิ์ตามข้อ 6. ท่านสามารถติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลตามรายละเอียดด้านล่าง<br />
          เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล<br />
          ชั้น 7 อาคารไบโอเฮ้าส์ เลขที่ 55 ซอยสุขุมวิท 39(ซอยพร้อมพงษ์) คลองตันเหนือ วัฒนา กรุงเทพฯ 10110
          เบอร์ติดต่อ: (66) 02-258-8888
        </li>
      </ol>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'text-box',
  data() {
    return {};
  },
};
</script>

<style scoped>
.card-text {
  font-size: 1rem;
  font-weight: 300;
}

.card-body {
  height: 56vh;
}

.rounded-5 {
  border-radius: 0.5rem;
}

.agreement-card p,
ol,
li {
  font-weight: 300
}

::marker {
  font-weight: 400;
}
</style>
