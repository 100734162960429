import "./assets/scss/bootstrap.scss";
import "bootstrap";
import { createApp } from "vue";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(faPenToSquare);

import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);

app.config.globalProperties.$liff = window.liff;

app
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
