import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://api.ncareclub.com/v1',
  headers: {
    'Content-Type': 'application/json',
    "x-api-key": "def8e0041c8f4cc0918773d15b474a77"
  },
  transformRequest: [
    data => {
      return JSON.stringify(data);
    },
  ],
  transformResponse: [
    data => {
      return JSON.parse(data);
    },
  ],
});

export default apiClient;
