import apiClient from '../axios'

function postRecords(customerId, recorded_date) {
  return apiClient.post('/records', {
    customerId,
    recorded_date,
  })
}

function getRecordsByCustomerId(customerId) {
  return apiClient.get(
    `/records?customerId=${customerId}&sort_by=recorded_date.desc`
  )
}

function patchRecord(recordId, date) {
  return apiClient.patch(`/records/${recordId}`, {
    recorded_date: date,
  })
}

export { postRecords, getRecordsByCustomerId, patchRecord }
