import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    userId: "", // Uid for api fetching
    lineUid: null, //for checking if user is registered
    lineDisplayname: null,
    record: {}, // for updating period record
  },
  mutations: {
    SET_USERID(state, userId) {
      state.userId = userId;
    },
    SET_RECORD(state, obj) {
      state.record = obj;
    },
    SET_LINEUID(state, lineUid) {
      state.lineUid = lineUid;
    },
    SET_LINEDISPLAYNAME(state, lineDisplayname) {
      state.lineDisplayname = lineDisplayname;
    },
    CLEAR_USERID(state) {
      state.userId = "";
    },
    CLEAR_RECORD(state) {
      state.record = {};
    },
  },
  actions: {
    setUserId({ commit }, userId) {
      commit("SET_USERID", userId);
    },
    setRecord({ commit }, record) {
      commit("SET_RECORD", record);
    },
    clearUserId({ commit }) {
      commit("CLEAR_USERID");
    },
    clearRecord({ commit }) {
      commit("CLEAR_RECORD");
    },
  },
  plugins: [createPersistedState()],
});
