<template>
  <FullPageSpinner v-if="loading"></FullPageSpinner>
  <div v-else class="py-4 container-fluid mx-auto">
    <h1 class="h1 pt-4 display-2 fw-normal text-primary text-center">
      บันทึกประจำเดือน<br />ย้อนหลัง 12 เดือน
    </h1>

    <div class="container mt-4">
      <div v-if="Object.keys(editDetail).length !== 0">
        <div class="pt-3 position-relative">
          <button @click="toEditPeriod" class="position-absolute top-0 end-0 btn btn-primary btn-sm">
            <span>
              <font-awesome-icon icon="fa-solid fa-pen-to-square" size="lg" />
            </span>
          </button>
        </div>
        <input-field v-for="(detail, index) in formDetails" :key="index" :detail="detail" :editDetail="editDetail" />
        <hr class="mt-4 mb-0 bg-white opacity-100" size="7" />

        <div class=" flex-datesection d-flex flex-row justify-content-center align-items-center w-100"
          v-for="(detail, index) in dateDetails" :key="index">
          <span class="fs-4">{{  index + 1  }}</span>
          <input-field :detail="detail" />
        </div>
        <Button :disabled="false" type="submit" class="mt-4" label="ปิด" @click="closeWindow" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InputField from '../../components/InputField.vue'
import Button from '../../components/Button.vue'
import * as api from '@/services'
import FullPageSpinner from '@/views/components/feedbacks/FullPageSpinner.vue'
export default {
  components: {
    InputField,
    Button,
    FullPageSpinner
  },
  async mounted() {
    const { data } = await api.getCustomerByLineUid(this.lineUid)
    const dateData = await api.getRecordsByCustomerId(data.results[0].id)
    // 12 เดือนล่าสุด
    const results = dateData.data.results.slice(0, 12)
    this.dateDetails = results
    this.editDetail = data.results[0]
    this.loading = false
  },
  data() {
    return {
      submit: 'submit',
      isEditted: false,
      formDetails: [
        {
          title: 'interval',
          label: 'ลักษณะประจำเดือน',
          type: 'edit',
        },
        {
          title: 'volume',
          label: 'ปริมาณ',
          type: 'edit',
        },
        {
          title: 'cycle',
          label: 'จำนวนวันต่อครั้ง',
          type: 'edit',
        },
      ],
      dateDetails: [],
      inputDetails: {},
      editDetail: {},
      loading: true
    }
  },
  methods: {
    async onSubmit() {
      this.inputDetails['phoneNumber'] = `${this.inputDetails['phoneNumber']}`
      await api.patchCustomer(this.userId, { ...this.inputDetails })
      this.isEditted = false
      return
    },
    toEditPeriod() {
      this.$router.push({
        path: '/edit-period-info',
      })
    },
    getInput(input) {
      this.inputDetails = { ...this.inputDetails, ...input }
    },
    closeWindow() {
      this.$liff.closeWindow()
    }
  },
  computed: {
    ...mapState(['userId', 'lineUid']),
  },
}
</script>
<style>
.flex-datesection {
  flex: 0 0 100%;
}

.flex-datesection>div {
  flex: 1;
}
</style>