import apiClient from '../axios';

function postRedemptions(data) {
  return apiClient.post('/redemptions', data);
}

function postRedemptionForm(data) {
  return apiClient.post('/redemptionform', data);
}

function getRedemptionsFormDataByUserId(customerId) {
  return apiClient.get(`/redemptionform?user_id=${customerId}`);
}

export { postRedemptions, postRedemptionForm, getRedemptionsFormDataByUserId };
