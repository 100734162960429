<template>
  <div class="row justify-content-center">
    <div :class="[
      info.length > 4 ? 'col-4' : 'col',
      'd-flex flex-column align-items-center px-2',
    ]" v-for="(item, index) in info" :key="index">
      <label @change="updateAnswer(answer)">
        <div>
          <input :type="type" :value="item.id ? item.id : item.title" v-model="answer" />
          <img :src="(item.selectedImg && answer === item.title) ? item.selectedImg : item.image"
            :alt="`Option-${index}`" :class="[
            imageClass ? imageClass : '',
            isShowBorder ? '' : 'not-border', 'w-100 rounded-3 bg-white ']" style="max-width: 100%;" />

          <p class="text-center text-primary fs-6 lh-sm fw-light pt-1">
            {{  isShowLabel ? item.title : ''  }}
          </p>
        </div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'v-button',
  data() {
    return {
      answer: this.initAnswer ? this.initAnswer : [],
    }
  },
  props: {
    info: {
      required: true,
    },
    type: {
      default: "checkbox"
    },
    name: null,
    isShowLabel: {
      type: Boolean,
      default: true
    },
    imageClass: null,
    initAnswer: null,
    isShowBorder: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    isiPad() {
      return navigator.userAgent.match(/iPad/i)
    }
  },
  methods: {
    updateAnswer(answer) {
      this.$emit('update', answer, this.name)
    },
  },
}
</script>
<style scoped>
/* HIDE RADIO */
[type='checkbox'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type='checkbox']+img {
  cursor: pointer;
}

/* CHECKED STYLES */
[type='checkbox']:checked+img {
  border-radius: 0.3rem;
  border: none;
  /* border: 2px solid #dc2d5a; */
  box-shadow: 0 0 0 2px #dc2d5a;
  outline: none;
  transition: .1s;
}

[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type='radio']+img {
  cursor: pointer;
}

/* CHECKED STYLES */
[type='radio']:checked+img {
  border-radius: 0.3rem;
  border: none;
  /* border: 2px solid #dc2d5a; */
  box-shadow: 0 0 0 2px #dc2d5a;
  outline: none;
  transition: .1s;

}

.not-border {
  border: none !important;
  box-shadow: none !important;

}
</style>
