<template>
  <div class="bg">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
</template>


<style lang="scss">
// @media (min-width: 960px) {
//   .bg {
//     border-radius: 24px;
//     width: 390px;
//     background-color: white;

//     h1 {
//       padding-top: 0px;
//       font-size: 2rem;
//     }

//     label {
//       font-size: 1.2rem;
//     }

//     .form-control-lg {
//       font-size: 1.2rem;
//     }

//     .dp__input {
//       font-size: 1.2rem;
//     }

//     .btn-lg {
//       font-size: 1.2rem;
//     }
//   }

//   #app {
//     background-image: url('../public/image/background.png');
//     display: flex;
//     justify-content: center;
//   }
// }

.bg {
  min-height: 100.2vh;
  background-image: url('../public/image/background.png');
  background-size: cover;
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
