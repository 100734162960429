<template>
    <div v-if="showDialog">
        <div class="overlay welcome-overlay" @click="showDialog = !showDialog"></div>
        <div class="welcome-dialog-wrapper  ">
            <Transition name="bounce" appear>
                <div class=" modal-dialog  modal-dialog-centered border-0 rounded-3">
                    <div class="modal-content bg-primary-2">
                        <div v-if="isHeader" class="modal-header border-bottom-0">
                            <h5 class="modal-title">
                                <slot name="header"></slot>
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                @click="showDialog = !showDialog"></button>
                        </div>
                        <div class="modal-body d-flex flex-column align-items-center">
                            <img src="/image/popup.png" class="w-90 mb-3 mt--2" />
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showDialog: true
        }
    },
    props: {
        isHeader: {
            type: Boolean,
            default: true
        }
    }, methods: {
        handleBtnClick() {
            this.showDialog = !this.showDialog
        }
    }

}
</script>
<style>
.mt--2 {
    margin-top: -3rem;
}

.w-80 {
    width: 80%
}

.w-90 {
    width: 90%
}

.welcome-dialog-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 1055;
}

.welcome-overlay {
    z-index: 0 !important;
    ;
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    transition: bounce-in 0.5s;
}

.transform-right {
    z-index: 1055;
    transform: translateX(-50%);

}



@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}
</style>