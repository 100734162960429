import apiClient from '../axios'

function postCustomer(data) {
  return apiClient.post('/customers', data)
}

function getCustomerById(id) {
  return apiClient.get(`/customers/${id}`)
}

function getCustomerByLineUid(lineUid) {
  return apiClient.get(`/customers?lineUid=${lineUid}`)
}

function patchCustomer(id, data) {
  return apiClient.patch(`/customers/${id}`, data)
}

export { postCustomer, getCustomerById, getCustomerByLineUid, patchCustomer }
