<template>
  <FullPageSpinner v-if="loading"/>
  <div v-else class="py-4 container-fluid mx-auto">
    <h1 class="h1 pt-4 display-2 fw-normal text-primary text-center">
      ข้อมูลประจำเดือน
    </h1>
    <div class="container mt-5 mb-2 mx-auto">
      <div class="mb-4">
        <label class="form-label">ลักษณะประจำเดือน</label>
        <TextRadio v-if="q1.length > 0" :info="q1" :name="0" @update="updateAnswer" :initAnswer="answers[0].value" />
      </div>
      <div class="mb-0">
        <label class="form-label">ปริมาณ</label>
        <image-radio v-if="q2.length > 0" :info="q2" :name="1" @update="updateAnswer" type="radio" :isShowBorder="false"
          :initAnswer="answers[1].value"></image-radio>
      </div>
      <div class="mb-2">
        <label class="form-label">จำนวณวันต่อครั้ง</label>
        <image-radio v-if="q3.length > 0" :info="q3" :name="2" @update="updateAnswer" :isShowLabel="false" type="radio"
          :initAnswer="answers[2].value" />
      </div>
      <div class="mb-2">
        <label class="form-label">อาการเมื่อมีประจำเดือน</label>
        <image-radio v-if="q4.length > 0" :info="q4" :name="3" @update="updateAnswer" imageClass="py-2"
          :initAnswer="answers[3].value"></image-radio>
      </div>
    </div>
    <Button :disabled="!isFinish" @click="handleSubmit">บันทึกข้อมูล</Button>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapState } from 'vuex'
import Button from '../../components/Button.vue'
import TextRadio from '../../components/TextRadio.vue'
import ImageRadio from '../../components/ImageRadio.vue'
import * as api from '@/services'
import FullPageSpinner from '@/views/components/feedbacks/FullPageSpinner.vue'
export default {
  async mounted() {
    const { data } = await api.getSymptoms()
    this.q4.forEach((eachRadio, index) => {
      eachRadio['title'] = data.results[index].name
      eachRadio['id'] = data.results[index].id
    })

    if (this.$route.name == "EditPeriodInfo") {
      const user = await api.getCustomerById(this.userId)
      const userData = user.data
      this.answers[0].value = userData.interval
      this.answers[1].value = userData.volume
      this.answers[2].value = userData.cycle
      await this.getSymptoms()
    }
    this.loading = false
  },
  data() {
    return {
      loading: true,
      answers: [{ value: '', isRequired: true }, { value: '', isRequired: true }, { value: '', isRequired: false }, { value: [], isRequired: false }],
      q1: [{ title: 'มาประจำ' }, { title: 'ไม่ประจำ' }],
      q2: [
        { image: '/image/1-drop.jpg', title: 'น้อย' , selectedImg: '/image/1-drop-selected.jpg'},
        { image: '/image/2-drop.jpg', title: 'ปกติ', selectedImg: '/image/2-drop-selected.jpg' },
        { image: '/image/3-drop.jpg', title: 'ค่อนข้างมาก' , selectedImg: '/image/3-drop-selected.jpg'},
        { image: '/image/4-drop.jpg', title: 'มาก', selectedImg: '/image/4-drop-selected.jpg' }],
      q3: [
        { image: '/image/2days.jpg', title: '2 วัน' },
        { image: '/image/3days.jpg', title: '3 วัน' },
        { image: '/image/5days.jpg', title: '5 วัน' },
        { image: '/image/7days.jpg', title: '7 วัน', },
        { image: '/image/more7days.jpg', title: 'มากกว่า 7 วัน', }],
      q4: [
        { id: '', image: '/image/sleep.png', title: 'ปวดท้อง' },
        { id: '', image: '/image/pimple.png', title: 'สิว' },
        { id: '', image: '/image/cry.png', title: 'อารมณ์แปรปรวน' },
        { id: '', image: '/image/eating.png', title: 'กินจุกจิก', }],
      curSymptoms: [] //สำหรับเช็คเวลา patch
    }
  },
  components: {
    Button,
    TextRadio,
    ImageRadio,
    FullPageSpinner
},
  computed: {
    isFinish() {
      let bool = true
      this.answers.forEach(element => {
        if (element.isRequired && !element.value) {
          bool = false
        }
      })
      return bool
    },
    ...mapState({ userId: 'userId' }),
  },
  methods: {
    updateAnswer(item, index) {
      this.answers[index].value = item
    },
    async getSymptoms() {
      const { data } = await api.getCustomerSymptomByCustomerId(this.userId)
      this.curSymptoms = data.results
      this.answers[3].value = this.curSymptoms.map(function (el) { return el.symptomId; });
    },
    async handleSubmit() {
      await api.patchCustomer(this.userId, {
        interval: this.answers[0].value,
        volume: this.answers[1].value,
        cycle: this.answers[2].value,
      })
      if (this.$route.name === "PeriodInfo") {
        await this.answers[3].value.forEach((item) => {
          api.postCustomerSymptom(item, this.userId)
        })
        this.$router.push({ path: `/pick-date` })
      } else { //สำหรับมาจาก path แก้ไข
        console.log(this.curSymptoms)
        for (let i = 0; i < this.curSymptoms.length; i++) {
          await api.deleteCustomerSymptom(this.curSymptoms[i].id).catch(err => console.log(err))
        }
        for (let i = 0; i < this.answers[3].value.length; i++) {
          await api.postCustomerSymptom(this.answers[3].value[i], this.userId)
        }
        this.getSymptoms()
        this.$router.push({ path: `/edit-period` })
      }
    },
  },
  setup() {
    const date = ref()
    return {
      date,
    }
  },
}
</script>
